import { PayrollComponent } from "./payroll/payroll.component";
import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { HomeComponent } from "./home/home.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./shared/material/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EmployeeDetailsComponent } from "./employee-details/employee-details.component";
import { AddEmployeeDetailsComponent } from "./add-employee-details/add-employee-details.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RemovewhitespacesPipe } from "./core/pipes/removeWHiteSpaces.pipe";
import { DateFormatPipe } from "./core/pipes/date-format.pipe";
import { FirstLetterToLowerCasePipe } from "./core/pipes/firstLetterToLowerCase.pipe";
import { ReplaceUnderscorePipe } from "./core/pipes/replaceUnderscore.pipe";
import { KeyValueOriginalOrderPipe } from "./core/pipes/keyValueOriginalOrder.pipe";
import { DndDirective } from "./employee-details/dnd.directive";
import { ProgressComponent } from "./employee-details/progress/progress.component";
import { SafePipe } from "./core/pipes/safepipe.pipe";
import { InvestmentDeclarationComponent } from "./investment-declaration/investment-declaration.component";
import { AuthService } from "./shared/services/auth.service";
import { LoginComponent } from "./account/login/login.component";
import { AuthGuard } from "./core/authentication/auth.guard";
import { UserPayrollComponent } from "./user-payroll/user-payroll.component";
import { MSAL_INSTANCE, MsalGuard, MsalModule } from "@azure/msal-angular";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { YtdDetailsComponent } from "./ytd-details/ytd-details.component";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatCardModule } from "@angular/material/card";
import { RequestInterceptor } from "./core/http/request.interceptor";
import { AdminSettingsComponent } from "./admin-settings/admin-settings.component";
import { MatRadioModule } from "@angular/material/radio";
import { MatExpansionModule } from "@angular/material/expansion";
import { SnackBarService } from "./shared/services/snackbar.service";
import { YtdDetailsAllEmployeesComponent } from "./ytd-details-all-employees/ytd-details-all-employees.component";
import { NgxPermissionsModule } from "ngx-permissions";
import { UserSettingsComponent } from "./user-settings/user-settings.component";
import { MatDividerModule } from "@angular/material/divider";
import { CamelCaseToTitlePipe } from "./core/pipes/camelCaseToTitle.pipe";
import { MatSelectModule } from "@angular/material/select";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { getDutchPaginatorIntl } from "./shared/paginator-intl";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatMenuModule } from "@angular/material/menu";
import { SpinnerComponent } from "./spinner/spinner.component";
import { PayslipComponent } from "./payslip/payslip.component";
import { AbbreviateMonthPipe } from './core/pipes/abbreviate-month-pipe.pipe';
import { InvestmentSectionCardComponent } from './investment-declaration/investment-section-card/investment-section-card.component';
import { InvestmentDeclarationDialogComponent } from './investment-declaration/investment-declaration-dialog/investment-declaration-dialog.component';
import { AddInvestmentComponent } from './investment-declaration/add-investment/add-investment.component';
import { AddInvestmentHRAFormComponent } from './investment-declaration/add-investment/add-investment-hra-form/add-investment-hra-form.component';
import { CtcEarningComponent } from "./admin-settings/ctc-earning/ctc-earning.component";
import { FirstLetterToUpperCasePipe } from "./core/pipes/firstLetterToUpperCase.pipe";
import { NumberToWordsPipe } from "./core/pipes/numberToWords.pipe";
import { CapitalizeFirstPipe } from "./core/pipes/capitalizeFirst.pipe";
import { UserNotFoundComponent } from "./user-not-found/user-not-found.component";
import { IncomeCardComponent } from './payslip/income-card/income-card.component';
import { EmployeePayslipDetailsComponent } from './payslip/employee-payslip-details/employee-payslip-details.component';
import { PayslipPdfComponent } from './payslip/payslip-pdf/payslip-pdf.component';
import { StringOnlyDirective } from "./core/directives/string-only.directive";
import { NumberOnlyDirective } from "./core/directives/number-only.directive";
import { PayrollDialogComponent } from './payroll-dialog/payroll-dialog.component';
import { SnackbarWithProgressComponent } from './snackbar-with-progress/snackbar-with-progress.component';
import { MatProgressBarModule } from "@angular/material";
import { InvestmentDeclarationPreviewComponent } from './investment-declaration/investment-declaration-preview/investment-declaration-preview.component';
import { EarningsDeductionsComponent } from './payroll/earnings-deductions/earnings-deductions.component';
import { EmployeeEarningsComponent } from './payroll/earnings-deductions/employee-earnings/employee-earnings.component';
import { EmployeeDeductionsComponent } from './payroll/earnings-deductions/employee-deductions/employee-deductions.component';
import { EditEmployeePersonalDetailsComponent } from "./employee-details/edit-employee-personal-details/edit-employee-personal-details.component";
import { AuthConfigService } from "./auth-config.service";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CtcDeductionComponent } from "./admin-settings/ctc-deduction/ctc-deduction.component";

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;


export function MSALInstanceFactory(authConfigService: AuthConfigService): IPublicClientApplication {
  return authConfigService.getPublicClientApp();
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HomeComponent,
    EmployeeDetailsComponent,
    AddEmployeeDetailsComponent,
    PayrollComponent,
    LoginComponent,
    RemovewhitespacesPipe,
    FirstLetterToLowerCasePipe,
    ReplaceUnderscorePipe,
    KeyValueOriginalOrderPipe,
    CamelCaseToTitlePipe,
    DndDirective,
    ProgressComponent,
    SafePipe,
    DateFormatPipe,
    InvestmentDeclarationComponent,
    UserPayrollComponent,
    ConfirmDialogComponent,
    YtdDetailsComponent,
    AdminSettingsComponent,
    YtdDetailsAllEmployeesComponent,
    UserSettingsComponent,
    SpinnerComponent,
    PayslipComponent,
    AbbreviateMonthPipe,
    InvestmentSectionCardComponent,
    InvestmentDeclarationDialogComponent,
    AddInvestmentComponent,
    AddInvestmentHRAFormComponent,
    FirstLetterToUpperCasePipe,
    NumberToWordsPipe,
    CapitalizeFirstPipe,
    UserNotFoundComponent,
    IncomeCardComponent,
    EmployeePayslipDetailsComponent,
    StringOnlyDirective,
    NumberOnlyDirective,
    PayslipPdfComponent,
    PayrollDialogComponent,
    SnackbarWithProgressComponent,
    InvestmentDeclarationPreviewComponent,
    EarningsDeductionsComponent,
    EmployeeEarningsComponent,
    EmployeeDeductionsComponent,
    EditEmployeePersonalDetailsComponent,
    CtcEarningComponent,
    CtcDeductionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatRadioModule,
    MatExpansionModule,
    MatSelectModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatProgressBarModule,
    NgxPermissionsModule.forRoot(),
    MsalModule.forRoot(null, null, null), // No direct initialization here
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [AuthConfigService],
    },
    AuthService,
    AuthGuard,
    MsalGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    SnackBarService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddEmployeeDetailsComponent,
    ConfirmDialogComponent,
    SpinnerComponent,
    InvestmentDeclarationDialogComponent,
    PayrollDialogComponent,
    SnackbarWithProgressComponent,
  ],
})
export class AppModule {}
