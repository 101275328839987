import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ICtcDeductionComponent } from "src/app/core/interfaces/admin-setting";

@Component({
  selector: "app-ctc-deduction",
  templateUrl: "./ctc-deduction.component.html",
  styleUrls: ["./ctc-deduction.component.scss"],
})
export class CtcDeductionComponent {
  @Input("ctcDeductForm")
  public ctcDeductForm: FormGroup;

  @Input("recentAddedDeductComp")
  public recentAddedDeductComp: ICtcDeductionComponent[];

  @Input("existingDeductComp")
  public existingDeductComp: ICtcDeductionComponent[];

  @Input("editDeductCompDetails")
  public editDeductCompDetails: ICtcDeductionComponent;

  @Input("editDeductCompIndex")
  public editDeductCompIndex: number;

  constructor(private formBuilder: FormBuilder) {
    this.ctcDeductForm = this.formBuilder.group({});
    this.recentAddedDeductComp = [] as ICtcDeductionComponent[];
    this.existingDeductComp = [] as ICtcDeductionComponent[];
    this.editDeductCompDetails = {} as ICtcDeductionComponent;
    this.editDeductCompIndex = -1;
  }

  public removeComponent = (index: number) => {
    this.recentAddedDeductComp.splice(index, 1);
  };

  public editComponent = (index: number) => {
    let removedData = this.recentAddedDeductComp.splice(index, 1);
    this.editDeductCompIndex = index;
    this.editDeductCompDetails = removedData[0];
    this.ctcDeductForm
      .get("componentName")
      .setValue(this.editDeductCompDetails.deductionName);
    this.ctcDeductForm
      .get("componentType")
      .setValue(this.editDeductCompDetails.type);
    this.ctcDeductForm
      .get("isTaxSaving")
      .setValue(this.editDeductCompDetails.isTaxSaving);
    this.ctcDeductForm
      .get("limit")
      .setValue(this.editDeductCompDetails.deductionLimit);
  };

  public updateRecentEarningList = () => {
    let updatedDetails = {
      deductionName: this.ctcDeductForm.value.componentName,
      deductionLimit: this.ctcDeductForm.value.limit,
      isTaxSaving: this.ctcDeductForm.value.isTaxSaving,
      type: this.ctcDeductForm.value.componentType,
    } as ICtcDeductionComponent;
    this.recentAddedDeductComp.splice(
      this.editDeductCompIndex,
      0,
      updatedDetails
    );
    this.editDeductCompIndex = -1;
    this.editDeductCompDetails = {} as ICtcDeductionComponent;
    this.ctcDeductForm.reset();
  };

  public cancelUpdateComponent = () => {
    this.recentAddedDeductComp.splice(
      this.editDeductCompIndex,
      0,
      this.editDeductCompDetails
    );
    this.editDeductCompIndex = -1;
    this.editDeductCompDetails = {} as ICtcDeductionComponent;
    this.ctcDeductForm.reset();
  };

  public addComponent = () => {
    const isTaxSaving =
      this.ctcDeductForm &&
      this.ctcDeductForm.value &&
      this.ctcDeductForm.value.isTaxSaving;
    this.recentAddedDeductComp.push({
      deductionName:
        this.ctcDeductForm &&
        this.ctcDeductForm.value &&
        this.ctcDeductForm.value.componentName
          ? this.ctcDeductForm.value.componentName
          : "-",

      deductionLimit:
        this.ctcDeductForm &&
        this.ctcDeductForm.value &&
        this.ctcDeductForm.value.limit &&
        typeof this.ctcDeductForm.value.limit === "string"
          ? this.ctcDeductForm.value.limit
          : "-",
      type:
        this.ctcDeductForm &&
        this.ctcDeductForm.value &&
        this.ctcDeductForm.value.componentType
          ? this.ctcDeductForm.value.componentType
          : "-",
      isTaxSaving: isTaxSaving == null ? "-" : isTaxSaving,
    });
    this.ctcDeductForm.reset();
  };
}
