import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ICtcEarningComponent } from "src/app/core/interfaces/admin-setting";

@Component({
  selector: "app-ctc-earning",
  templateUrl: "./ctc-earning.component.html",
  styleUrls: ["./ctc-earning.component.scss"],
})
export class CtcEarningComponent {
  @Input("ctcEarnForm")
  public ctcEarnForm: FormGroup;

  @Input("recentAddedEarnComp")
  public recentAddedEarnComp: ICtcEarningComponent[];

  @Input("existingEarnComp")
  public existingEarnComp: ICtcEarningComponent[];

  @Input("editEarnCompDetails")
  public editEarnCompDetails: ICtcEarningComponent;

  @Input("editEarnCompIndex")
  public editEarnCompIndex: number;

  constructor(private formBuilder: FormBuilder) {
    this.ctcEarnForm = this.formBuilder.group({});
    this.recentAddedEarnComp = [] as ICtcEarningComponent[];
    this.existingEarnComp = [] as ICtcEarningComponent[];
    this.editEarnCompDetails = {} as ICtcEarningComponent;
    this.editEarnCompIndex = -1;
  }

  public removeComponent = (index: number) => {
    this.recentAddedEarnComp.splice(index, 1);
  };

  public editComponent = (index: number) => {
    let removedData = this.recentAddedEarnComp.splice(index, 1);
    this.editEarnCompIndex = index;
    this.editEarnCompDetails = removedData[0];
    this.ctcEarnForm
      .get("componentName")
      .setValue(this.editEarnCompDetails.componentName);
    this.ctcEarnForm
      .get("componentType")
      .setValue(this.editEarnCompDetails.type);
    this.ctcEarnForm
      .get("isTaxable")
      .setValue(this.editEarnCompDetails.taxable === "Y" ? true : false);
  };

  public updateRecentEarningList = () => {
    let updatedDetails = {
      taxable: this.ctcEarnForm.value.isTaxable ? "Y" : "N",
      componentName: this.ctcEarnForm.value.componentName,
      type: this.ctcEarnForm.value.componentType,
    } as ICtcEarningComponent;
    this.recentAddedEarnComp.splice(this.editEarnCompIndex, 0, updatedDetails);
    this.editEarnCompIndex = -1;
    this.editEarnCompDetails = {} as ICtcEarningComponent;
    this.ctcEarnForm.reset();
  };

  public cancelUpdateComponent = () => {
    this.recentAddedEarnComp.splice(
      this.editEarnCompIndex,
      0,
      this.editEarnCompDetails
    );
    this.editEarnCompIndex = -1;
    this.editEarnCompDetails = {} as ICtcEarningComponent;
    this.ctcEarnForm.reset();
  };

  public addComponent = () => {
    const isTaxable =
      this.ctcEarnForm &&
      this.ctcEarnForm.value &&
      this.ctcEarnForm.value.isTaxable;
    this.recentAddedEarnComp.push({
      componentName:
        this.ctcEarnForm &&
        this.ctcEarnForm.value &&
        this.ctcEarnForm.value.componentName
          ? this.ctcEarnForm.value.componentName
          : "-",
      type:
        this.ctcEarnForm &&
        this.ctcEarnForm.value &&
        this.ctcEarnForm.value.componentType
          ? this.ctcEarnForm.value.componentType
          : "-",
      taxable: isTaxable == null ? "-" : isTaxable ? "Y" : "N",
    });
    this.ctcEarnForm.reset();
  };
}
