import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { HomeComponent } from "./home/home.component";
import { EmployeeDetailsComponent } from "./employee-details/employee-details.component";
import { PayrollComponent } from "./payroll/payroll.component";
import { InvestmentDeclarationComponent } from "./investment-declaration/investment-declaration.component";
import { LoginComponent } from "./account/login/login.component";
import { AuthGuard } from "./core/authentication/auth.guard";
import { UserPayrollComponent } from "./user-payroll/user-payroll.component";
import { YtdDetailsComponent } from "./ytd-details/ytd-details.component";
import { AdminSettingsComponent } from "./admin-settings/admin-settings.component";
import { YtdDetailsAllEmployeesComponent } from "./ytd-details-all-employees/ytd-details-all-employees.component";
import { NgxPermissionsGuard } from "ngx-permissions";
import { PAGE_TO_GROUPS_MAPPING } from "./core/authorization/permissions";
import { AuthorizationGuard } from "./core/authorization/authorization.guard";
// import { UserSettingsComponent } from "./user-settings/user-settings.component";
import { UserNotFoundComponent } from "./user-not-found/user-not-found.component";

const routes: Routes = [
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard, AuthorizationGuard],
    children: [
      {
        path: "",
        redirectTo: "home",
        pathMatch: "full",
      },
      {
        path: "home",
        component: HomeComponent,
      },
      {
        path: "empDetails",
        component: EmployeeDetailsComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: PAGE_TO_GROUPS_MAPPING.empDetails,
            // redirectTo: '/denied'
          },
        },
      },
      {
        path: "payroll",
        component: PayrollComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: PAGE_TO_GROUPS_MAPPING.payroll,
          },
        },
      },
      {
        path: "investmentDeclaration",
        // canDeactivate: [InvestmentDeclarationGuard],
        component: InvestmentDeclarationComponent,
      },
      {
        path: "userPayroll",
        component: UserPayrollComponent,
      },
      {
        path: "ytdDetails",
        component: YtdDetailsComponent,
      },
      {
        path: "allEmployeesYtdDetails",
        component: YtdDetailsAllEmployeesComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: PAGE_TO_GROUPS_MAPPING.allEmployeesYtdDetails,
          },
        },
      },
      {
        path: "admin-settings",
        component: AdminSettingsComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: PAGE_TO_GROUPS_MAPPING.adminSettings,
          },
        },
      },
      // {
      //   path: "user-settings",
      //   component: UserSettingsComponent,
      // },
    ],
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "user-not-found",
    component: UserNotFoundComponent,
  },
  { path: "", pathMatch: "full", redirectTo: "dashboard" },
];

const isIframe = window !== window.parent && !window.opener;
const isPopup = window.opener && window !== window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
