import { HttpParams, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";
import { ICtcFormDetails } from "../../core/interfaces/add-employee";

@Injectable({
  providedIn: "root",
})
export class EmployeeService {
  private path;
  private param: HttpParams;
  userDetails: any;
  newUserAdded:boolean=false;

  constructor(private baseService: BaseService) {}

  getAllEmployeesDetailsApi(): Observable<any> {
    this.path = "employee";
    return this.baseService.get(this.path);
  }

  postEmployeeDetailsApi(data: any): Observable<any> {
    this.path = "employee";
    return this.baseService.post(this.path, data);
  }

  postEmployeeCTCDetailsApi(data: any): Observable<any> {
    this.path = "employeeCtc";
    return this.baseService.post(this.path, data);
  }

  getCTCComponentsApi(): Observable<any> {
    this.path = "options/CTC_COMPONENT";
    return this.baseService.get(this.path);
  }

  getAllCTCComponentsApi(): Observable<ICtcFormDetails[]> {
    this.path = "ctc";
    return this.baseService.get(this.path);
  }

  calculateCTCApi(data: any): Observable<any> {
    this.path = "payrollCalc";
    return this.baseService.post(this.path, data);
  }

  getEmployeeCTCDetailsApi(empId: any, fiscal: string): Observable<any> {
    // this.param = new HttpParams().set("employeeId", empId);
    // this.path = `employee-ctc/getEmployeeCtcDetail`;
    this.path = `employeeCtc/employeeCtcDetailByEmployeeId?employeeId=${empId}&fiscal=${fiscal}`;
    return this.baseService.get(this.path);
  }

  getEmployeeDetailsApi(empId: any): Observable<any> {
    this.param = new HttpParams().set("employeeId", empId);
    this.path = `employee/getEmployeeById`;
    return this.baseService.get(this.path, this.param);
  }

  getEmployeeDetailsByEmailApi(email: any): Observable<any> {
    this.path = `employee/employeeByEmail?emailAddress=${email}`;
    return this.baseService.get(this.path);
  }

  postBulkFileUploadApi(data: any): Observable<any> {
    this.path = "employee/bulkImportEmployee";
    return this.baseService.post(this.path, data);
  }

  deleteEmployeeByIdApi(empId: any): Observable<any> {
    this.path = `employee?employeeId=${empId}`;
    return this.baseService.delete(this.path);
  }

  getEmployeeDetailsByNameApi(name: string) {
    this.param = new HttpParams().set("name", name);
    this.path = `employee/employeeByName`;
    return this.baseService.get(this.path, this.param);
  }

  getEmployeesExcelApi(): Observable<any> {
    this.path = "employee/employeesExcel";
    return this.baseService.getExcel(this.path);
  }
  postEmployeeMonthlyLeaves(body): Observable<any> {
    this.path = "employeeMonthlyLeaves";
    return this.baseService.post(this.path, body);
  }

  getEmployeeMonthlyLeaves(
    empId: string,
    leaveYear: number,
    leaveMonth: number
  ): Observable<any> {
    this.path = `employeeMonthlyLeaves?employeeId=${empId}&leaveYear=${leaveYear}&leaveMonth=${leaveMonth}`;
    return this.baseService.get(this.path);
  }

  postEmployeePFPreferenceApi(body) {
    this.path = "employeePf";
    return this.baseService.post(this.path, body);
  }

  getEmployeeOnboardForm(): Observable<any> {
    this.path = "employee/onboardForm";
    return this.baseService.getExcel(this.path);
  }

  updateEmployeeCtc(body): Observable<any> {
    this.path = "employeeCtc";
    return this.baseService.put(this.path, body);
  }
  updateEmployeeDetails(body): Observable<any> {
    this.path = "employee";
    return this.baseService.put(this.path, body);
  }
}