import { Injectable } from '@angular/core';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class AuthConfigService {
  publicClientApp: IPublicClientApplication;

  constructor() {
    this.publicClientApp = new PublicClientApplication({
      auth: {
        clientId: environment.clientId,
        authority: "https://login.microsoftonline.com/b9806c7d-9280-4e44-afea-6dc0ff495c2f",
        //redirectUri: "http://localhost:4200/"
         redirectUri: environment.redirectUri,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
      },
    });
  }

  getPublicClientApp(): IPublicClientApplication {
    if (!this.publicClientApp) {
      throw new Error('PublicClientApplication instance is not initialized');
    }
    return this.publicClientApp;
  }
}
