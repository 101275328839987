import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { EmployeeService } from "../shared/services/employee.service";
import { PayrollService } from "../shared/services/payroll.service";
import { SpinnerService } from "../shared/services/spinner.service";
import {
  EmployeeDetails,
  EmployeePayslipDetails,
} from "../core/interfaces/user";
import { catchError, finalize, take } from "rxjs/operators";
import { of } from "rxjs";
import {
  TOTAL_DEDUCTIONS_STRING,
  TOTAL_INCOME_STRING,
  PayslipTableData,
  MONTH,
  EarningsOrDeductionArray,
  EmployeePayrollDetails,
  PAYSLIP_COMPONENT,
  PAYSLIP_COMPONENT_AMOUNT,
  EARNINGS,
  DEDUCTIONS,
  PAYSLIP_SUMMARY,
  SNACKBAR_LABEL,
  NAME_KEY,
  USER_KEY,
  USER_DETAILS,
  UserDetails,
} from "./payslip.utility";
import { SnackBarService } from "../shared/services/snackbar.service";
@Component({
  selector: "app-payslip",
  templateUrl: "./payslip.component.html",
  styleUrls: ["./payslip.component.scss"],
})
export class PayslipComponent implements OnInit {
  @Input() selectedEmployeeDetails: EmployeePayslipDetails;
  @Input() showTable: boolean;
  @Input() userPayroll: boolean;
  @Input() payrollMonth: number = this.getCurrentMonth();
  @Input() payrollYear: number = this.getCurrentYear();
  @Input() payrollDetails: EmployeePayrollDetails;
  @Output() dataEvent: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild("userPayslipPDF", { static: true }) userPayslipPDF: ElementRef;

  userDetails: UserDetails[] = USER_DETAILS;
  netPay: number;
  months: { [month: number]: string } = MONTH;
  user_details: EmployeeDetails;
  empJoiningYear: number;
  fileName: string;
  years: number[] = [];
  durationInSeconds = 5;
  selectedPayslipMonth: number;
  selectedPayslipYear: number;
  employeePayrollDetails: EmployeePayrollDetails;
  employeePayrollDetailsForPdf: PayslipTableData[];
  payslipExistForSelectedYearOrSelectedMonth: boolean = false;

  constructor(
    public employeeService: EmployeeService,
    private snackBarService: SnackBarService,
    private payrollService: PayrollService,
    private spinnerService: SpinnerService
  ) {
    this.selectedPayslipMonth = this.getCurrentMonth();
    this.selectedPayslipYear = this.getCurrentYear();
    this.user_details = JSON.parse(localStorage.getItem(USER_KEY));
  }

  ngOnInit() {
    if (!this.selectedEmployeeDetails) {
      this.selectedEmployeeDetails = this.user_details;
      this.setLWP();
      this.getPayrollDetails();
    } else if (this.payrollMonth || this.payrollYear || this.payrollDetails) {
      this.selectedPayslipMonth = this.payrollMonth;
      this.selectedPayslipYear = this.payrollYear;
      this.employeePayrollDetails = this.payrollDetails;
      this.getPdfDetailsAndNetPay();
    }

    this.selectedEmployeeDetails[
      NAME_KEY
    ] = `${this.selectedEmployeeDetails.firstName} ${this.selectedEmployeeDetails.lastName}`;
    this.empJoiningYear = parseInt(
      this.user_details.dateOfJoining.split("/")[2]
    );
    this.years = Array.from(
      { length: this.selectedPayslipYear - this.empJoiningYear + 1 },
      (_, i) => this.selectedPayslipYear - i
    );
    this.emitPayslipMonthValue();
  }

  setLWP() {
    if (this.selectedEmployeeDetails) {
      this.employeeService
        .getEmployeeMonthlyLeaves(
          this.selectedEmployeeDetails.employeeId,
          this.selectedPayslipYear,
          this.selectedPayslipMonth
        )
        .subscribe((lwp) => {
          this.selectedEmployeeDetails.lwpCount = lwp.leaveCount;
          this.setEffectiveWorkingDays(lwp.leaveCount);
        });
    }
  }

  setEffectiveWorkingDays(lwpCount: number) {
    const daysInMonth = new Date(
      this.selectedPayslipYear,
      this.selectedPayslipMonth,
      0
    ).getDate();
    this.selectedEmployeeDetails.effectiveWorkingDays = daysInMonth - lwpCount;
  }

  emitPayslipMonthValue(): void {
    const data = this.selectedPayslipMonth;
    this.dataEvent.emit(data);
  }

  getPayrollDetails(): void {
    this.spinnerService.openSpinnerDialog();
    this.payrollService
      .getPayrollDetailsApi(
        this.selectedEmployeeDetails.employeeId,
        this.selectedPayslipMonth,
        this.selectedPayslipYear
      )
      .pipe(
        take(1),
        catchError((error) => {
          this.snackBarService.add({
            message: error.error.message,
            action: SNACKBAR_LABEL,
            config: {
              duration: this.durationInSeconds * 1000,
              panelClass: ["custom-snackbar-class"],
            },
          });
          this.payslipExistForSelectedYearOrSelectedMonth = false;
          return of(null);
        }),
        finalize(() => {
          this.spinnerService.closeSpinnerDialog();
        })
      )
      .subscribe((res) => {
        if (res) {
          this.employeePayrollDetails = res;
          this.getPdfDetailsAndNetPay();
        }
      });
  }

  getPdfDetailsAndNetPay(): void {
    this.payslipExistForSelectedYearOrSelectedMonth = true;
    //for assigning the name to the payslip
    const payslipSummary = this.employeePayrollDetails[PAYSLIP_SUMMARY][0];
    this.fileName = `${payslipSummary.employeeId}_${payslipSummary.payslipMonth}_${payslipSummary.fiscal}.pdf`;

    const netPayComponent = this.employeePayrollDetails[PAYSLIP_SUMMARY].find(
      (component) => component.payslipComponent === "NET_PAY"
    );

    this.netPay = netPayComponent ? netPayComponent.componentAmount : 0;

    this.employeePayrollDetailsForPdf = this.pdfDetails(
      this.employeePayrollDetails
    );
  }
  pdfDetails(employeePayrollDetails): PayslipTableData[] {
    const parsedData: PayslipTableData[] = [];

    // Defined for code readability
    const earnings = employeePayrollDetails.earnings;
    const deductions = employeePayrollDetails.deductions;

    // case where API does not return earning or deductions is also handled
    const earningsLength = earnings ? earnings.length : 0;
    const deductionsLength = deductions ? deductions.length : 0;
    const counter: number = Math.max(earningsLength, deductionsLength);

    if (counter !== 0) {
      for (let i = 0; i < counter; i++) {
        const data = {
          earningsComponent: earnings
            ? earnings[i]
              ? earnings[i].payslipComponent.replace(/_/g, " ")
              : ""
            : "",
          earningsComponentAmount: earnings
            ? earnings[i]
              ? earnings[i].componentAmount
              : ""
            : "",
          deductionsComponent: deductions
            ? deductions[i]
              ? deductions[i].payslipComponent.replace(/_/g, " ")
              : ""
            : "",
          deductionsComponentAmount: deductions
            ? deductions[i]
              ? deductions[i].componentAmount
              : ""
            : "",
        };
        parsedData.push(data);
      }

      let totalIncome = 0;
      let totalDeductions = 0;
      for (const component of employeePayrollDetails.payslipSummary) {
        if (component.payslipComponent === TOTAL_DEDUCTIONS_STRING) {
          totalDeductions = component.componentAmount;
        } else if (component.payslipComponent === TOTAL_INCOME_STRING) {
          totalIncome = component.componentAmount;
        }
      }
      parsedData.push({
        earningsComponent: "Total Income",
        earningsComponentAmount: totalIncome.toString(),
        deductionsComponent: "Total Deductions",
        deductionsComponentAmount: totalDeductions.toString(),
      });
    }
    return parsedData;
  }

  onYearChange(): void {
    if (this.selectedPayslipYear === this.getCurrentYear()) {
      if (this.selectedPayslipMonth > this.getCurrentMonth()) {
        this.selectedPayslipMonth = 1;
      }
    }
    this.getPayrollDetails();
    this.setLWP();
  }

  getCurrentMonth(): number {
    return new Date().getMonth() > 0 ? new Date().getMonth() : 12;
  }

  getCurrentYear(): number {
    return new Date().getMonth() > 0
      ? new Date().getFullYear()
      : new Date().getFullYear() - 1;
  }

  onMonthChange(): void {
    this.getPayrollDetails();
    this.emitPayslipMonthValue();
    this.setLWP();
  }

  getEarningsOrDeductionsArray(
    type: typeof EARNINGS | typeof DEDUCTIONS
  ): EarningsOrDeductionArray {
    const result: EarningsOrDeductionArray = [];
    let incomeDetails = this.employeePayrollDetails[type];

    if (incomeDetails) {
      for (let item of incomeDetails) {
        result.push({
          label: item[PAYSLIP_COMPONENT].replace(/_/g, " "),
          amount: item[PAYSLIP_COMPONENT_AMOUNT],
        });
      }
    }
    return result;
  }

  getTotalEarningsOrDeductions(
    type: typeof EARNINGS | typeof DEDUCTIONS
  ): EarningsOrDeductionArray {
    const total: EarningsOrDeductionArray = [];
    for (let item of this.employeePayrollDetails[PAYSLIP_SUMMARY]) {
      if (
        (type === EARNINGS &&
          item[PAYSLIP_COMPONENT] === TOTAL_INCOME_STRING) ||
        (type === DEDUCTIONS &&
          item[PAYSLIP_COMPONENT] === TOTAL_DEDUCTIONS_STRING)
      ) {
        total.push({
          label: "Total",
          amount: item[PAYSLIP_COMPONENT_AMOUNT],
        });
      }
    }
    return total;
  }

  showMonth(monthKey: number): boolean {
    return (
      monthKey <= this.getCurrentMonth() &&
      this.getCurrentYear() == this.selectedPayslipYear
    );
  }

  showYear(): boolean {
    return this.getCurrentYear() != this.selectedPayslipYear;
  }
}
