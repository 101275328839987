import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";
import {
  ICtcDeductionComponent,
  ICtcEarningComponent,
} from "src/app/core/interfaces/admin-setting";
export type ConfigDetailsRequestPayload = {
  adminComponent: string;
  configuredSubComponent: string;
  status: boolean;
  fiscal: string;
  description: string;
};

export type ConfigDetailsByFiscalResponse = {
  settingId: number;
  adminComponent: string;
  status: boolean;
  fiscal: string;
  configuredSubComponent: string;
  description: string;
};

export type AdminConfigComponentInfo = {
  adminSubComponent: string;
  description: string;
  sequenceNumber: number;
};

export type AllAdminConfigComponentsResponse = {
  [index: string]: AdminConfigComponentInfo[];
};

export type TimeBoundFeature = {
  id: number;
  featureName: string;
  featureDescription: string;
  featureRoute: string;
};

export interface TimeBoundFeatureConfiguration {
  featureName: string;
  startDate: string;
  endDate: string;
}

export interface TimeBoundFeatureConfigurationWithFiscal
  extends TimeBoundFeatureConfiguration {
  fiscal: string;
}

@Injectable({
  providedIn: "root",
})
export class AdminService {
  private path: any;
  constructor(private baseService: BaseService) {}

  getAllAdminConfigComponents(): Observable<any> {
    this.path = `adminComponent`;
    return this.baseService.get(this.path);
  }

  getConfigDetailsByFiscalApi(
    fiscal: number
  ): Observable<ConfigDetailsByFiscalResponse[]> {
    this.path = `admin/config/${fiscal}`;
    return this.baseService.get(this.path);
  }

  getCtcEarningExistingComponents(): Observable<ICtcEarningComponent[]> {
    this.path = `ctc`;
    return this.baseService.get(this.path);
  }

  getCtcDeductionExistingComponents(): Observable<ICtcDeductionComponent[]> {
    this.path = `deductionsXref `;
    return this.baseService.get(this.path);
  }

  postCtcEarningExistingComponents(
    reqBody: ICtcEarningComponent[]
  ): Observable<{ message: string }> {
    this.path = `ctc`;
    return this.baseService.post(this.path, reqBody);
  }

  postCtcDeductionExistingComponents(
    reqBody: ICtcDeductionComponent[]
  ): Observable<{ message: string }> {
    this.path = `deductionsXref `;
    return this.baseService.post(this.path, reqBody);
  }

  postConfigDetailsApi(payload: ConfigDetailsRequestPayload[]) {
    this.path = `admin/config`;
    return this.baseService.post(this.path, payload);
  }

  getAllTimeBoundFeaturesListApi(): Observable<TimeBoundFeature[]> {
    this.path = "timeBound/featuresXref";
    return this.baseService.get(this.path);
  }

  getAllTimeBoundFeaturesConfigurationsApi(
    fiscal: string
  ): Observable<TimeBoundFeatureConfiguration[]> {
    this.path = `timeBound/featureRequests/${fiscal}`;
    return this.baseService.get(this.path);
  }

  postTimeBoundFeatureConfigurationApi(
    featureConfig: TimeBoundFeatureConfigurationWithFiscal
  ): Observable<TimeBoundFeatureConfigurationWithFiscal> {
    this.path = "timeBound/featureRequests";
    return this.baseService.post(this.path, featureConfig);
  }

  deleteTimeBoundFeatureConfigurationByNameApi(
    fiscal: string,
    featureName: string
  ): Observable<any> {
    this.path = `timeBound/featureRequests/${fiscal}/${featureName}`;
    return this.baseService.delete(this.path);
  }
}
